import React, { useState, useEffect } from "react";
import "./Thankyou.css";
import { newLogo } from "./../../img/imageLinks";
import { Link } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

const Thankyou = (props) => {
  const [timeLeft, setTimeLeft] = useState(10);

  useEffect(() => {
    const options = {
      autoConfig: true, 
      debug: false, // enable logs
    };

    ReactPixel.init('422143188869813', options);
    ReactPixel.track('Lead');
    ReactPixel.pageView();
  }, [])

  useEffect(() => {
    const timer =
      timeLeft > 0 &&
      setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  useEffect(() => {
    if (timeLeft <= 0) {
      return props.history.push("/dashboard");
    }
  }, [timeLeft]);
  return (
    <div className="thank-you">
      <div className="inside-page">
        <div className="text-center">
          {/* <h1 className="h3 header">DEBUGSBUNNY QUIZ</h1> */}
          <img src={newLogo} alt="debugsbunny logo" className="img-logo" />
        </div>
        <div className="text-center">
          <h1 className="h2 header">Welcome to DebugsBunny </h1>
        </div>
        <div className="text-center">
          <h1 className="h6 header">
            We are excited you are joining DebugsBunny.{" "}
          </h1>
        </div>
        <h6 style={{fontWeight:"600"}}>
          DebugsBuuny provides Live interactive Coding sessions and a free HP
          Laptop to ensure every student has access to good quality education.
          Book a demo class to start your exploration in the world of coding.
        </h6>

        <div className="text-center">
          <h1 className="h6 header">
            In next {timeLeft} {timeLeft > 1 ? "secs" : "sec"} you will be
            redirected to Dashboard.
          </h1>
        </div>

        <div className="text-center button-div">
          <Link to="/dashboard" className="link">
            <button className="btn btn-lg btn-warning btn-block">
              <h1 className="h6 header">Go To Dashboard</h1>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
