const {
  SLOT_REQ,
  SLOT_BOOKED,
  SLOT_FAILED,
  SLOT_SUCCESS,
  SLOT_DELETED,
  SLOT_DETAILS,
  START_LECTURE,
  END_LECTURE,
  LECTURE_REQ,
  LECTURE_SLOT_FAILED,
} = require('../actions/type');

const initialState = {
  loading: false,
  slots: [],
  error: '',
  slotBooked: false,
  slotDetails: {},
  lectureLoading: false,
  lectureEnded: false
};

const slotReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SLOT_REQ:
      return { ...state, loading: true };
    case SLOT_SUCCESS:
      const { data } = payload;
      return {
        ...state,
        loading: false,
        slots: data,
      };
    case SLOT_BOOKED: {
      return {
        ...state,
        loading: false,
        slots: [...state.slots, payload.data],
        slotBooked: true,
      };
    }
    case SLOT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case SLOT_DELETED: {
      const newSlots = state.slots.filter((slot) => slot.id !== payload.id);
      return {
        ...state,
        slots: newSlots,
        loading: false,
      };
    }
    case LECTURE_REQ:
      return {
        ...state,
        lectureLoading: true,
      };
    case SLOT_DETAILS:
      return {
        ...state,
        slotDetails: payload.data,
        lectureLoading: false,
      };
    case START_LECTURE:
      return {
        ...state,
        lectureLoading: false,
      };
    case END_LECTURE:
      return {
        ...state,
        lectureLoading: false,
        lectureEnded: true
      };
    case LECTURE_SLOT_FAILED:
      return {
        ...state,
        lectureLoading: false,
      };
    default:
      return state;
  }
};

export default slotReducer;
