import { combineReducers } from "redux";
import userReducer from "./userReducer";
import authReducer from "./authReducer";
import slotReducer from "./slotReducer";
import lectureReducer from "./lectureReducer";
import referralReducer from "./referralReducer";
import alertReucer from "./alertReducer";
import walletReducer from "./walletReducer";
import courseReducer from "./courseReducer";
import paymentReducer from "./paymentReducer";
import quizReducer from "./quizReducer";
import resourceReducer from "./resourceReducer";
import gameReducer from "./gameReducer";
import otherReducer from "./otherReducer";

export default combineReducers({
  user: userReducer,
  auth: authReducer,
  slot: slotReducer,
  lecture: lectureReducer,
  referral: referralReducer,
  alert: alertReucer,
  wallet: walletReducer,
  course: courseReducer,
  payment: paymentReducer,
  quiz: quizReducer,
  resource: resourceReducer,
  game: gameReducer,
  other:otherReducer
});
