import axios from 'axios';
import { get } from 'lodash';
import {
    UPLOAD_GAME,
    UPLOAD_GAME_SUCCESS,
    UPLOAD_GAME_FAILURE,
    GET_GAMES,
    GET_GAMES_SUCCESS,
    GET_GAMES_FAILURE
} from './type';

export const uploadGame = (payload) => async (dispatch) => {
    dispatch({
        type: UPLOAD_GAME,
    });
    try {
        const res = await axios.put(
            `/schedule/lecture/upload`, payload
        );
        dispatch({
            type: UPLOAD_GAME_SUCCESS,
            payload: { ...res.data.data },
        });
    } catch (err) {
        const errors = get(err, "response.data.message");
        dispatch({
            type: UPLOAD_GAME_FAILURE,
            payload: errors,
        });
    }
};

export const getAllGames = () => async (dispatch) => {
    dispatch({
        type: GET_GAMES,
    });
    try {
        const res = await axios.get(
            `/schedule/ranking`
        );
        dispatch({
            type: GET_GAMES_SUCCESS,
            payload: { ...res.data.data }
        });
    } catch (err) {
        const errors = get(err, "response.data.message");
        dispatch({
            type: GET_GAMES_FAILURE,
            payload: errors,
        });
    }
};