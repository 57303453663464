import { lazy } from "react";
import Thankyou from "./components/layout/Thankyou";
import ResourceHome from "./components/resoucesDownload/ResourceHome";
import UploadForm from "./components/game/uploadForm";



const Login = lazy(() => import("./components/auth/Login"));
const Register = lazy(() => import("./components/auth/Register"));
const Dashboard = lazy(() => import("./components/dashboard/Dashboard"));
const Payment = lazy(() => import("./components/layout/Payment"));
const PaymentCourseSummary = lazy(() =>
  import("./components/layout/PaymentCourseSummary")
);
const LectureCollaborator = lazy(() =>
  import("./components/codePlatform/LectureCollaborator")
);
const Subscriptions = lazy(() => import("./components/layout/Subscriptions"));

const Profile = lazy(() => import("./components/profile/Profile"));
const Checkout = lazy(() => import("./components/payment/Checkout"));
const CheckoutAllCourse = lazy(() =>
  import("./components/payment/CheckoutAllCourse")
);
const ForgotPassword = lazy(() => import("./components/auth/ForgotPassword"));
const TeacherProfile = lazy(() =>
  import("./components/trainer/TeacherProfile")
);
const Quiz = lazy(() => import("./components/quiz/Quiz"));
const GameDashboard = lazy(() => import("./components/game/gameDashboard"));
const GamePlayArea = lazy(() => import("./components/game/gamePlayArea"));
const PrivateQuizResult = lazy(() =>
  import("./components/privateQuiz/PrivateQuizResult")
);
const PrivateQuizContinue = lazy(() =>
  import("./components/privateQuiz/PrivateQuizContinue")
);
const GameHome = lazy(()=>
  import('./components/BlocklyGame/GameHome')
);

export const ROUTES = [
  {
    exact: true,
    path: "/login",
    component: Login,
  },
  {
    exact: true,
    path: "/register",
    component: Register,
  },
  {
    exact: true,
    path: "/dashboard/:tab?",
    component: Dashboard,
  },
  {
    exact: true,
    path: "/lecture/:id",
    component: LectureCollaborator,
  },
  {
    exact: true,
    path: "/profile",
    component: Profile,
  },
  {
    exact: true,
    path: "/teacher-profile",
    component: TeacherProfile,
  },
  {
    exact: true,
    path: "/subscription",
    component: Subscriptions,
  },
  {
    exact: true,
    path: "/checkout",
    component: Checkout,
  },
  {
    exact: true,
    path: "/checkout/:id",
    component: CheckoutAllCourse,
  },
  {
    exact: true,
    path: "/payment-course/summary",
    component: PaymentCourseSummary,
  },
  {
    exact: true,
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    exact: true,
    path: "/quiz/:id",
    component: Quiz,
  },
  {
    exact: true,
    path: "/resources",
    component: ResourceHome,
  },
  {
    exact: true,
    path: "/thank-you",
    component: Thankyou,
  },
  {
    exact: true,
    path: "/payment/:segmentHeader/:segmentId",
    component: Payment,
  },
  {
    exact: true,
    path: "/upload/:seqNo/:lectureId",
    component: UploadForm,
  },
  {
    exact: true,
    path: "/apps",
    component: GameDashboard,
  },
  {
    exact: true,
    path: "/apps/play",
    component: GamePlayArea,
  },

  {
    exact: true,
    path: "/privateQuiz/result",
    component: PrivateQuizResult,
  },
  {
    exact: true,
    path: "/privateQuiz/start",
    component: PrivateQuizContinue,
  },
  {
    exact: true,
    path: "/dashboard/privateQuiz/start",
    component: PrivateQuizContinue,
  },
  {
    exact: true,
    path: "/dashboard/privateQuiz/continue",
    component: PrivateQuizContinue,
  },
  {
    exact: true,
    path: "/privateQuiz/continue",
    component: PrivateQuizContinue,
  },
  {
    exact:true,
    path:"/play-maze",
    component:GameHome
  },
  {
    exact:true,
    path:"/game-maze",
    component:GameHome
  }
];
