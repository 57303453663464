import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ExitToApp, AccountCircle } from "@material-ui/icons";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import { getAllApplicableCourses } from "../../actions/course";
import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import affiliate from "../../resource/Inside_Affiliate.pdf";
import logoutIcon from "./../../img/logoutIcon.svg";
import { newLogo } from "./../../img/imageLinks.js";
import { btnStyle } from "../../utils/commonStyles";
import { noInternetImage } from "./../../img/imageLinks";

const useStyle = makeStyles(() => ({
  root: {
    color: "#FBD741",
    height: "3rem",
    width: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionBar: {
  },
}));

const Navbar = ({
  logout,
  authenticated,
  role,
  subLoading,
  history
}) => {
  const location = useLocation();
  const classes = useStyle();
  const btn = btnStyle();
  const path = location.pathname;

  const authLinks = () => (
    <Fragment>
      <div className="col-md-10 col-8">
        <div className="navbar-brand">
          <Link to="/dashboard">
            <img className="navbar-brand-img" src={newLogo} alt="Home" />
          </Link>
        </div>
      </div>
      <div className="col-md-1 col-2">
        <Link to="/apps">
          <Button className={btn.primary} id="gamepad-btn">
            <i className="fa fa-gamepad" />
          </Button>
        </Link>
      </div>
      <div className="col-md-1 col-2">
        <Grid className={classes.actionBar}>
          <div className="nav-item dropdown">
            <a href="#!" className="d-block" data-toggle="dropdown">
              <AccountCircle className={classes.root} id="profile-icon" />
            </a>
            <div className="dropdown-menu dropdown-menu-left account-dropdown">
              <Link to="/profile" className="dropdown-item">
                Profile
            </Link>
            </div>
          </div>
        </Grid>
      </div>
    </Fragment>
  );

  const logoutUser = () => {
    logout();
    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  };

  const teacherAuthLinks = () => (
    <Fragment>
      <div className="col-md-10 col-8">
        <div className="navbar-brand">
          <Link to="/dashboard">
            <img className="navbar-brand-img" src={newLogo} alt="Home" />
          </Link>
        </div>
      </div>
      <div className="col-md-1 col-2">
        <Link to="/apps">
          <Button className={btn.primary} id="gamepad-btn">
            <i className="fa fa-gamepad" />
          </Button>
        </Link>
      </div>
      <div className="col-md-1 col-2">
        <Grid className={classes.actionBar}>
          <div className="nav-item dropdown">
            <a href="#!" className="d-block" data-toggle="dropdown">
              <AccountCircle className={classes.root} id="profile-icon" />
            </a>
            <div className="dropdown-menu dropdown-menu-left account-dropdown">
              <Link to="/teacher-profile" className="dropdown-item">
                Profile
            </Link>
              <button className="dropdown-item d-flex" onClick={logoutUser}>
                <Typography>Logout &nbsp;</Typography>
                <ExitToApp />
              </button>
            </div>
          </div>
        </Grid>
      </div>
    </Fragment>
  );

  const noAuthLinks = () => (
    <Fragment>
      <div className="navbar-brand">
        <Link to="/login">
          <img className="navbar-brand-img" src={newLogo} alt="Home" />
        </Link>
      </div>
      <div
        onClick={() => window.open("//www.debugsbunny.com", "_self")}
        style={{ position: "absolute", right: "1vw" }}
      >
        <Button style={{ padding: "0 1vw" }} className={btn.primary}>
          Go to Home
        </Button>
      </div>
    </Fragment>
  );

  return (
    <div className={path === "/" ? "landing" : "nonLanding"}>
      <header id="header">
        <nav className="navbar navbar-expand-lg">
          {
            // path === "/"
            //   ? landingLinks() :
            authenticated
              ? role === "TEACHER"
                ? teacherAuthLinks()
                : authLinks()
              : noAuthLinks()
          }
        </nav>
        {
          <img
            className="navbar-brand-img"
            src={noInternetImage}
            alt="No Internet"
            style={{ display: "none" }}
          />
        }
      </header>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.validated,
  role: state.auth.role,
  subLoading: state.course.subLoading
});

const enhance = connect(mapStateToProps, {
  logout
});

export default enhance(Navbar);