import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loader from "../layout/Loader";
import { uploadGame } from "../../actions/game";
import {
  Box,
  Button,
  Card,
  FilledInput,
  FormControl,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import "./game.css";
import LinkIcon from "@material-ui/icons/Link";
import { isUrlValid } from "../../utils/commonUtils";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { uploadSmallIcn, AppNameImg } from "../../img/imageLinks.js";

const useStype = makeStyles((theme) => ({
  card: {
    backgroundColor: "white",
    marginTop: "20px",
    marginBottom: "20px",
    width: "13cm",
    fontFamily: "DM Sans",
    borderRadius: "2%",
    [theme.breakpoints.down("xs")]: {
      width: "9cm",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "22px",
    textAlign: "center",
    margin: "10px",
    paddingTop: "2%",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
    },
  },
  table: {
    "& td": {
      paddingLeft: "10px",
    },
  },
  input: {
    "& .MuiFilledInput-input": {
      padding: "10px 12px 10px",
    },
    "& .MuiFilledInput-root": {
      borderRadius: "8px !important",
      backgroundColor: "#EFF0F6",
    },
    "& .MuiFilledInput-multiline": {
      paddingTop: "0",
    },
    marginBottom: "10px",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
  },
  btn: {
    backgroundColor: "#0052D4",
    color: "white !important",
    textTransform: "none",
    paddingLeft: "40px",
    paddingRight: "40px",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "blue",
      color: "white",
    },
  },
}));

const UploadForm = ({ uploading, match, uploadGame, history, uploaded }) => {
  const [description, setDescription] = useState("");
  const [appName, setAppName] = useState("");
  const [url, setUrl] = useState("");
  const classes = useStype();
  const lectureNumber = match.params.seqNo;
  const lectureId = match.params.lectureId;
  const studentName = history.location.state;

  useEffect(() => {
    if (uploaded) {
      let interval = setTimeout(() => {
        window.location.href = "/dashboard/past";
      }, 3000);
      return () => clearTimeout(interval);
    }
  }, [uploaded]);

  if (uploaded) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Card className={classes.card}>
          <div className="scale-in-center">App Uploaded Successfully!</div>
        </Card>
      </div>
    );
  } else {
    return (
      <Loader loading={uploading}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card className={classes.card}>
            <div className={classes.title}>Upload Your App</div>
            <Box mx={4}>
              <hr></hr>
              <table className={classes.table}>
                <tr>
                  <th>Name of Student :</th>
                  <td>{studentName}</td>
                </tr>
                <tr>
                  <th>Lecture No. :</th>
                  <td>{lectureNumber}</td>
                </tr>
              </table>

              <div>
                <b>
                  <p>Application Name :</p>
                </b>
                <FormControl
                  className={classes.input}
                  fullWidth
                  variant="filled"
                >
                  <FilledInput
                    disableUnderline
                    onChange={(e) => setAppName(e.currentTarget.value)}
                    id="filled-adornment-password"
                    placeholder="Enter Your Application Name"
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          style={{ marginBottom: "14px" }}
                          src={AppNameImg}
                          alt="app-icon"
                          width="17"
                        />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>

              <div>
                <b>
                  <p>
                    Application Link :&nbsp;
                    {!isUrlValid(url) && url && (
                      <small className="text-danger">Invalid URL</small>
                    )}
                  </p>
                </b>
                <FormControl
                  className={classes.input}
                  fullWidth
                  variant="filled"
                >
                  <FilledInput
                    disableUnderline
                    onChange={(e) => setUrl(e.currentTarget.value)}
                    id="filled-adornment-password"
                    placeholder="Enter Your Application Link"
                    startAdornment={
                      <InputAdornment position="start">
                        <LinkIcon style={{ marginBottom: "10px" }} />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>

              <div>
                <b>
                  <p>Description :</p>
                </b>
                <TextField
                  className={classes.input}
                  id="filled-multiline-static"
                  fullWidth
                  multiline
                  rows={3}
                  variant="filled"
                  placeholder="Add Your Description Here"
                  onChange={(e) => setDescription(e.currentTarget.value)}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <DescriptionOutlinedIcon
                          style={{ marginBottom: "50px" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 300 }}
                />
              </div>
              <div className={classes.button}>
                <Button
                  disabled={
                    !appName || !description || !url || !isUrlValid(url)
                  }
                  className={classes.btn}
                  onClick={() =>
                    uploadGame({
                      gameDescription: description,
                      gameName: appName,
                      gameUrl: url,
                      lectureScheduleId: parseInt(lectureId, 10),
                    })
                  }
                >
                  <img
                    src={uploadSmallIcn}
                    alt="uploadSmallIcn"
                    style={{ marginRight: "10px" }}
                  />
                  Upload
                </Button>
              </div>
            </Box>
          </Card>
        </div>
      </Loader>
    );
  }
};

const mapStateToProps = (state) => ({
  uploading: state.game.uploading,
  uploaded: state.game.uploaded,
});

const enhance = connect(mapStateToProps, {
  uploadGame,
});

export default enhance(UploadForm);