export const numSuffixOf = (i) => {
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

export function windowOpen(url) {
  if (!url.match(/^https?:\/\//i)) {
    url = 'http://' + url;
  }
  return window.open(url, "_blank");
}

export function isUrlValid(userInput) {
  let res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  if (res === null)
    return false;
  else
    return true;
}