export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";

export const OTP_REQUEST = "OTP_REQUEST";
export const OTP_SUCCESS = "OTP_SUCCESS";
export const OTP_FAILED = "OTP_FAILED";
export const OTP_VALIDATE = "OTP_VALIDATE";
export const OTP_VALIDATED = "OTP_VALIDATED";
export const OTP_CANCELLED = "OTP_CANCELLED";
export const OTP_VALIDATION_FAILED = "OTP_VALIDATION_FAILED";

export const LOGIN_REQ = "LOGIN_REQ";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SLOT_REQ = "SLOT_REQ";
export const SLOT_BOOKED = "SLOT_BOOKED";
export const SLOT_FAILED = "SLOT_FAILED";
export const SLOT_SUCCESS = "SLOT_SUCCESS";
export const SLOT_DELETED = "SLOT_DELETED";
export const GET_LECTURE = "GET_LECTURE";
export const LECTURE_SUCCESS = "LECTURE_SUCCESS";
export const LECTURE_FAILED = "LECTURE_FAILED";
export const GET_ARCHIVE = "GET_ARCHIVE";
export const ARCHIVE_SUCCESS = "PARCHIVE_SUCCESS";
export const ARCHIVE_FAILED = "ARCHIVE_FAILED";
export const REQ_REFERRAL = "REQ_REFERRAL";
export const REFERRAL_SUCCESS = "REFERRAL_SUCCESS";
export const REFERRAL_FAILED = "REFERRAL_FAILED";
export const REQ_REFERRAL_STATUS = "REQ_REFERRAL_STATUS";
export const REFERRAL_STATUS_SUCCESS = "REFERRAL_STATUS_SUCCESS";
export const REFERRAL_STATUS_FAILED = "REFERRAL_STATUS_FAILED";
export const REWARDS_SUCCESS = "REWARDS_SUCCESS";
export const SET_ALERT = "SET_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const REQ_WALLLET = "REQ_WALLLET";
export const WALLET_SUCCESS = "WALLET_SUCCESS";
export const WALLET_FAILED = "WALLET_FAILED";
export const START_LECTURE = "START_LECTURE";
export const END_LECTURE = "END_LECTURE";
export const SLOT_DETAILS = "SLOT_DETAILS";
export const LECTURE_REQ = "LECTURE_REQ";
export const LECTURE_SLOT_FAILED = "LECTURE_SLOT_FAILED";
export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAILED = "PROFILE_FAILED";

export const GET_SEGMENT_COURSE = "GET_SEGMENT_COURSE";
export const GET_SEGMENT_COURSE_SUCCESS = "GET_SEGMENT_COURSE_SUCCESS";
export const GET_SEGMENT_COURSE_FAILED = "GET_SEGMENT_COURSE_FAILED";

export const GET_SUBSCRIBED_COURSES = "GET_SUBSCRIBED_COURSES";
export const GET_SUBSCRIBED_COURSES_SUCCESS = "GET_SUBSCRIBED_COURSES_SUCCESS";
export const GET_SUBSCRIBED_COURSES_FAILED = "GET_SUBSCRIBED_COURSES_FAILED";

export const INITIALISE_PAYMENT_SUCCESS = "INITIALISE_PAYMENT_STARTED";
export const INITIALISE_PAYMENT_STARTED = "INITIALISE_PAYMENT_SUCCESS";
export const INITIALISE_PAYMENT_FAILED = "INITIALISE_PAYMENT_FAILED";
export const PAYU_REDIRECT = "PAYU_REDIRECT";
export const GET_PAYMENT_HISTORY = "GET_PAYMENT_HISTORY";
export const GET_PAYMENT_HISTORY_SUCCESS = "GET_PAYMENT_HISTORY_SUCCESS";
export const GET_PAYMENT_HISTORY_FAILED = "GET_PAYMENT_HISTORY_FAILED";

export const LECTURE_BOOK_REQ = "LECTURE_BOOK";
export const LECTURE_BOOK_SUCCESS = "LECTURE_BOOK_SUCCESS";
export const LECTURE_BOOK_FAILED = "LECTURE_BOOK_FAILED";
export const LECTURE_SLOT_BOOK_REQ = "LECTURE_SLOT_BOOK_REQ";
export const LECTURE_SLOT_BOOK_SUCCESS = "LECTURE_SLOT_BOOK_SUCCESS";
export const LECTURE_SLOT_BOOK_FAILED = "LECTURE_SLOT_BOOK_FAILED";
export const SCHEDULE_CANCEL_REQ = "LECTURE_SLOT_CANCEL_REQ";
export const SCHEDULE_CANCEL_SUCCESS = "LECTURE_SLOT_CANCEL_SUCCESS";
export const SCHEDULE_CANCEL_FAILED = "LECTURE_SLOT_CANCEL_FAILED";
export const GET_ALL_APPLICABLE_COURSES = "ALL_APPLICABLE_COURSE";
export const GET_ALL_APPLICABLE_COURSES_SUCCESS =
    "ALL_APPLICABLE_COURSES_SUCCESS";
export const GET_ALL_APPLICABLE_COURSES_FAILED =
    "ALL_APPLICABLE_COURSES_FAILED";

export const SAVE_CODEORG_DATA = "SAVE_CODEORG_DATA";
export const GET_CODEORG_DATA = "GET_CODEORG_DATA";
export const GET_CODEORG_DATA_SUCCESS = "GET_CODEORG_DATA_SUCCESS";
export const GET_CODEORG_DATA_FAILURE = "GET_CODEORG_DATA_FAILURE";

export const USER_DETAILS_INCOMPLETE = "USER_DETAILS_INCOMPLETE";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const SUBMIT_FEEDBACK = "SUBMIT_FEEDBACK";
export const SUBMIT_FEEDBACK_SUCCESS = "SUBMIT_FEEDBACK_SUCCESS";
export const SUBMIT_FEEDBACK_FAILURE = "SUBMIT_FEEDBACK_FAILURE";

export const GET_BATCHES_FOR_TEACHER = "GET_BATCHES_FOR_TEACHER";
export const GET_BATCHES_FOR_TEACHER_SUCCESS =
    "GET_BATCHES_FOR_TEACHER_SUCCESS";
export const GET_BATCHES_FOR_TEACHER_FAILURE =
    "GET_BATCHES_FOR_TEACHER_FAILURE";

export const TEACHER_BATCH_ENROLL = "TEACHER_BATCH_ENROLL";
export const TEACHER_BATCH_ENROLL_SUCCESS = "TEACHER_BATCH_ENROLL_SUCCESS";
export const TEACHER_BATCH_ENROLL_FAILURE = "TEACHER_BATCH_ENROLL_FAILURE";

export const SAVE_LECTURE_ID = "SAVE_LECTURE_ID";

export const MARK_STUDENT_ATTENDANCE = "MARK_STUDENT_ATTENDANCE";
export const MARK_STUDENT_ATTENDANCE_SUCCESS =
    "MARK_STUDENT_ATTENDANCE_SUCCESS";
export const MARK_STUDENT_ATTENDANCE_FAILURE =
    "MARK_STUDENT_ATTENDANCE_FAILURE";

export const FORGOT_PASSWORD_OTP_REQUEST = "FORGOT_PASSWORD_OTP_REQUEST";
export const FORGOT_PASSWORD_OTP_SUCCESS = "FORGOT_PASSWORD_OTP_SUCCESS";
export const FORGOT_PASSWORD_OTP_FAILED = "FORGOT_PASSWORD_OTP_FAILED";
export const INFO_OTP_VALIDATE = "INFO_OTP_VALIDATE";
export const INFO_OTP_VALIDATED = "INFO_OTP_VALIDATED";
export const INFO_OTP_CANCELLED = "INFO_OTP_CANCELLED";
export const INFO_OTP_VALIDATION_FAILED = "INFO_OTP_VALIDATION_FAILED";
export const UPDATE_PROFILE_DETAILS = "UPDATE_PROFILE_DETAILS";
export const UPDATE_PROFILE_DETAILS_SUCCESS = "UPDATE_PROFILE_DETAILS_SUCCESS";
export const UPDATE_PROFILE_DETAILS_FAILURE = "UPDATE_PROFILE_DETAILS_FAILURE";

export const QUIZ_DATA = "QUIZ_DATA";
export const QUIZ_DATA_SUCCESS = "QUIZ_DATA_SUCCESS";
export const QUIZ_DATA_FAILURE = "QUIZ_DATA_FAILURE";

export const QUIZ_USER_DATA_CHECK = "QUIZ_USER_DATA_CHECK";
export const QUIZ_USER_DATA_CHECK_SUCCESS = "QUIZ_USER_DATA_CHECK_SUCCESS";
export const QUIZ_USER_DATA_CHECK_FAILURE = "QUIZ_USER_DATA_CHECK_FAILURE";

export const QUIZ_GET_USER_RESULT = "QUIZ_GET_USER_RESULT";
export const QUIZ_GET_USER_RESULT_SUCCESS = "QUIZ_GET_USER_RESULT_SUCCESS";
export const QUIZ_GET_USER_RESULT_FAILURE = "QUIZ_GET_USER_RESULT_FAILURE";

export const PRIVATE_QUIZ_GET = "PRIVATE_QUIZ_GET";
export const PRIVATE_QUIZ_GET_SUCCESS = "PRIVATE_QUIZ_GET_SUCCESS";
export const PRIVATE_QUIZ_GET_FAILURE = "PRIVATE_QUIZ_GET_FAILURE";

export const DASHBOARD_PRIVATE_QUIZ_START = "DASHBOARD_PRIVATE_QUIZ_START";
export const DASHBOARD_PRIVATE_QUIZ_START_SUCCESS =
  "DASHBOARD_PRIVATE_QUIZ_START_SUCCESS";
export const DASHBOARD_PRIVATE_QUIZ_START_FAILURE =
  "DASHBOARD_PRIVATE_QUIZ_START_FAILURE";

export const DASHBOARD_QUIZ_DATA = "DASHBOARD_QUIZ_DATA";
export const DASHBOARD_QUIZ_DATA_SUCCESS = "DASHBOARD_QUIZ_DATA_SUCCESS";
export const DASHBOARD_QUIZ_DATA_FAILURE = "DASHBOARD_QUIZ_DATA_FAILURE";

export const RESOURCE_VALIDATE_REQUEST = "RESOURCE_VALIDATE_REQUEST";
export const RESOURCE_VALIDATE_REQUEST_SUCCESS =
    "RESOURCE_VALIDATE_REQUEST_SUCCESS";
export const RESOURCE_VALIDATE_REQUEST_FAILURE =
    "RESOURCE_VALIDATE_REQUEST_FAILURE";

export const RESOURCE_OTP_VALIDATE = "RESOURCE_OTP_VALIDATE";
export const RESOURCE_OTP_VALIDATE_SUCCESS = "RESOURCE_OTP_VALIDATE_SUCCESS";
export const RESOURCE_OTP_VALIDATE_FAILURE = "RESOURCE_OTP_VALIDATE_FAILURE";

export const GET_PRIVATE_QUIZ_RESULT = "GET_PRIVATE_QUIZ_RESULT";
export const GET_PRIVATE_QUIZ_RESULT_SUCCESS =
  "GET_PRIVATE_QUIZ_RESULT_SUCCESS";
export const GET_PRIVATE_QUIZ_RESULT_FAILURE =
  "GET_PRIVATE_QUIZ_RESULT_FAILURE";

export const PRIVATE_QUIZ_START = "PRIVATE_QUIZ_START";
export const PRIVATE_QUIZ_START_SUCCESS = "PRIVATE_QUIZ_START_SUCCESS";
export const PRIVATE_QUIZ_START_FAILURE = "PRIVATE_QUIZ_START_FAILURE";

export const GET_PRIVATE_QUIZ_DATA = "GET_PRIVATE_QUIZ_DATA";
export const GET_PRIVATE_QUIZ_DATA_SUCCESS = "GET_PRIVATE_QUIZ_DATA_SUCCESS";
export const GET_PRIVATE_QUIZ_DATA_FAILURE = "GET_PRIVATE_QUIZ_DATA_FAILURE";

export const UPDATE_PRIVATE_QUIZ_RESPONSE = "UPDATE_PRIVATE_QUIZ_RESPONSE";
export const UPDATE_PRIVATE_QUIZ_RESPONSE_SUCCESS =
  "UPDATE_PRIVATE_QUIZ_RESPONSE_SUCCESS";
export const UPDATE_PRIVATE_QUIZ_RESPONSE_FAILURE =
  "UPDATE_PRIVATE_QUIZ_RESPONSE_FAILURE";

export const PRIVATE_QUIZ_SUBMIT = "PRIVATE_QUIZ_SUBMIT_RESPONSE";
export const PRIVATE_QUIZ_SUBMIT_SUCCESS = "PRIVATE_QUIZ_SUBMIT_SUCCESS";
export const PRIVATE_QUIZ_SUBMIT_FAILURE = "PRIVATE_QUIZ_SUBMIT_FAILURE";
export const UPLOAD_GAME = "UPLOAD_GAME";
export const UPLOAD_GAME_SUCCESS = "UPLOAD_GAME_SUCCESS";
export const UPLOAD_GAME_FAILURE = "UPLOAD_GAME_FAILURE";
export const GET_GAMES = "GET_GAMES";
export const GET_GAMES_SUCCESS = "GET_GAMES_SUCCESS";
export const GET_GAMES_FAILURE = "GET_GAMES_FAILURE";
// export const GET_RESOURCE = "GET_RESOURCE";
// export const GET_RESOURCE_SUCCESS = "GET_RESOURCE_SUCCESS";
// export const GET_RESOURCE_FAILURE = "GET_RESOURCE_FAILURE";

export const GET_APPLICABLE_COURSES_DATA = "GET_APPLICABLE_COURSES_DATA";
export const GET_APPLICABLE_COURSES_DATA_SUCCESS =
    "GET_APPLICABLE_COURSES_DATA_SUCCESS";
export const GET_APPLICABLE_COURSES_DATA_FAILED =
    "GET_APPLICABLE_COURSES_DATA_FAILED";

export const VALIDATE_COUPON_CODE = "VALIDATE_COUPON_CODE";
export const VALIDATE_COUPON_CODE_SUCCESS = "VALIDATE_COUPON_CODE_SUCCESS";
export const VALIDATE_COUPON_CODE_FAILED = "VALIDATE_COUPON_CODE_FAILED";
export const TEACHER_GET_PROFILE = "TEACHER_GET_PROFILE";
export const TEACHER_PROFILE_SUCCESS = "TEACHER_PROFILE_SUCCESS";
export const TEACHER_PROFILE_FAILED = "TEACHER_PROFILE_FAILED";

export const GET_CONTACT_SUPPORT = "GET_CONTACT_SUPPORT";
export const GET_CONTACT_SUPPORT_SUCCESS = "GET_CONTACT_SUPPORT_SUCCESS";
export const GET_CONTACT_SUPPORT_FAILURE = "GET_CONTACT_SUPPORT_FAILURE";
